<template>
  <section class="repetitive-container">
    <v-btn class="repetitive-button" :color="'#FF827C'" width="93px" height="26px" @click="openModal"> Repetitive </v-btn>
    <transition name="opacity-ease">
      <div v-if="isOpen" :class="`repetitive-modal ${themeName}`">
        <img
          v-if="themeName === 'dark'"
          class="close-btn"
          src="@/assets/Close_Line_12px_1_Icon_dark.svg"
          alt="close"
          @click="closeModal"
          width="12"
        />
        <img v-else class="close-btn" src="@/assets/Close_Line_12px_1_Icon.svg" alt="close" @click="closeModal" width="12" />
        <div class="repetitive-text">Repetitive of</div>
        <div class="repetitive-info">
          {{ rootLogInfo }}
          <img @click="moveToRootLog" class="chevron-btn" src="@/assets/Chevron-Right_16px_Icon_red.svg" alt="chevron" />
        </div>
      </div>
    </transition>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import Theme from '@/mixin/theme.mixin'
import Util from '@/util'

export default {
  mixins: [Theme],
  props: {
    rootLogId: {
      type: Number,
      default: -1,
    },
  },
  async mounted() {
    this.rootLog = await this.getLog(this.rootLogId)
  },
  data() {
    return {
      isOpen: false,
      rootLog: {},
      // defaultData: {
      //   camera_name: 'CameraName123',
      //   created_at: '2022-09-26T00:45:18Z',
      //   id: 113990,
      //   offset: '-7',
      // },
    }
  },
  computed: {
    rootLogInfo() {
      const createdTime = this.rootLog.created_at
      const offset = this.rootLog.offset
      const time = Util.getOffsetDate(createdTime, offset)

      return `[${time}] ${this.rootLog.camera_name}`
    },
  },
  methods: {
    ...mapActions(['getLog']),
    openModal() {
      this.isOpen = true
    },
    closeModal() {
      this.isOpen = false
    },
    moveToRootLog() {
      this.$emit('showRootLog', this.rootLog)
      this.closeModal()
    },
  },
}
</script>

<style lang="scss" scoped>
.repetitive-container {
  position: relative;
  margin-left: 40px;
}

.repetitive-button {
  color: var(--f-text-white);
  font-weight: 700;
  font-size: 12px;
  line-height: 170%;
  padding: 3px 20px;
  letter-spacing: normal;
}

.repetitive-modal {
  position: absolute;
  width: 366px;
  padding: 20px 4px 20px 20px;
  border-radius: 16px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
  transform: translateY(10px);
  &.dark {
    background-color: var(--f-bg-middle);
  }
  &.light {
    background-color: #ffffff;
  }
}

.repetitive-text {
  font-weight: 700;
  font-size: 14px;
  line-height: 170%;
}

.repetitive-info {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17.2px;
}

.close-btn {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 15px;
}

.chevron-btn {
  cursor: pointer;
  margin-left: auto;
  margin-right: 20px;
}

.opacity-ease-enter-active {
  animation: opacity-ease 0.3s ease-out both;
}

.opacity-ease-leave-active {
  animation: opacity-ease 0.2s reverse ease;
}

@keyframes opacity-ease {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
