<template>
  <div :class="`weather-info ${themeName}`">
    <div class="weather-info-row">
      <div :class="`weather-label ${themeName}`">Temperature</div>

      <div :class="`value ${themeName}`">{{ temperature }}</div>
    </div>
    <div :class="`split-line ${themeName}`"></div>
    <div class="weather-info-row">
      <div :class="`weather-label ${themeName}`">Wind Speed</div>

      <div :class="`value ${themeName}`">{{ windSpeed }}</div>
    </div>
    <div :class="`split-line ${themeName}`"></div>
    <div class="weather-info-row">
      <div :class="`weather-label ${themeName}`">Wind Direction</div>

      <div :class="`value ${themeName}`">{{ windDirection }}</div>
    </div>
    <div :class="`split-line ${themeName}`"></div>
    <div class="weather-info-row">
      <div :class="`weather-label ${themeName}`">Relative Humidity</div>

      <div :class="`value ${themeName}`">{{ relativeHumidity }}</div>
    </div>
    <div :class="`split-line ${themeName}`"></div>
    <div class="weather-info-row">
      <div :class="`weather-label ${themeName}`">Station</div>

      <v-tooltip :disabled="isOverflow" :content-class="`station-tooltip ${themeName}`" left nudge-top="60" nudge-right="134">
        <template v-slot:activator="{ on, attrs }">
          <div ref="station" :class="`value handle-text-overflow ${themeName}`" v-bind="attrs" v-on="on" @mouseover.stop.prevent="overflowCheck">
            {{ station }}
          </div>
        </template>
        <div>{{ station }}</div>
      </v-tooltip>
    </div>
  </div>
</template>

<script>
import Theme from '@/mixin/theme.mixin'
import { mapState } from 'vuex'
import Tooltip from '@desktop/superadminOrWorker/alert/Tooltip'

export default {
  mixins: [Theme],
  components: { Tooltip },
  data() {
    return {
      timer: null,
      stationName: 'none',
      overflowClassName: false,

      isOverflow: true,
    }
  },
  methods: {
    overflowCheck() {
      const offsetWidth = this.$refs.station.offsetWidth
      const scrollWidth = this.$refs.station.scrollWidth
      if (offsetWidth !== scrollWidth) this.isOverflow = false
      else this.isOverflow = true
    },
  },
  computed: {
    ...mapState(['currentLog']),
    temperature() {
      if (this.currentLog && this.currentLog.temperature) {
        return this.currentLog.temperature
      }
      return '-'
    },
    windSpeed() {
      if (this.currentLog && this.currentLog.wind_speed) {
        return this.currentLog.wind_speed
      }
      return '-'
    },
    windDirection() {
      if (this.currentLog && this.currentLog.wind_direction) {
        return this.currentLog.wind_direction
      }
      return '-'
    },
    relativeHumidity() {
      if (this.currentLog && this.currentLog.relative_humidity) {
        return this.currentLog.relative_humidity
      }
      return '-'
    },
    station() {
      if (this.currentLog && this.currentLog.station_name) {
        this.stationName = this.currentLog.station_name
        return this.currentLog.station_name
      }
      return '-'
    },
  },
}
</script>

<style lang="scss" scoped>
.weather-info {
  display: flex;
  align-items: center;
  min-height: 69px;
  border-radius: 16px;
  padding: 0 30px;
  font-weight: 600;
  font-size: 14px;
  justify-content: space-around;
  background-color: var(--f-light-gray-1);
  &.dark {
    background-color: var(--f-bg-middle);
  }

  .weather-info-row {
    display: flex;
    justify-content: space-around;

    width: 25%;
    max-width: 400px;
    .weather-label {
      font-weight: 500;
      color: var(--f-text-gray);
      white-space: nowrap;
      &.dark {
        color: var(--f-text-white-high);
      }
    }

    .value {
      font-weight: 600;
      white-space: nowrap;
      color: var(--f-text-black);
      &.dark {
        color: var(--f-primary-50);
      }

      &.handle-text-overflow {
        text-overflow: ellipsis;
        overflow: hidden;
        width: 50%;
        min-width: 125px;
        text-align: end;
      }
    }
  }
}

.split-line {
  width: 1px;
  height: 30px;
  background-color: var(--f-light-gray-2);
  &.dark {
    background-color: var(--f-bg-high);
  }
}

.station-tooltip {
  box-sizing: border-box;
  padding: 10px 20px 12px 20px;
  background-color: var(--v-backgroundAsideTooltip-base);
  box-shadow: 5px 5px 20px rgba(61, 81, 251, 0.1);
  border-radius: 10px;
  margin-top: 10px;
  z-index: 2;
  font-size: 14px;
  color: var(--v-textDefault);
  height: 39px;

  opacity: 1 !important;
  &.dark {
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.25);
  }
}
</style>
