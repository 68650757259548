<template>
  <div
    :class="{
      'event-circle': true,
      fire: value === 2,
      'non-fire': value === 3,
      unknown: value === 4,
      planned: value === 5,
      live: value === 1006,
    }"
    data-testid="EventType_Fire_Ellipse"
  ></div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 1,
    },
  },
}
</script>

<style lang="scss" scoped>
.event-circle {
  min-width: 10px;
  min-height: 10px;
  border-radius: 10px;
  border: 1px solid var(--f-light-gray-2);

  &.fire {
    border-width: 0;
    background-color: var(--f-primary-90);
  }

  &.non-fire {
    border-width: 0;
    background-color: var(--f-supplementary-green);
  }

  &.unknown {
    border-width: 0;
    background-color: var(--f-supplementary-blue);
  }

  &.planned {
    border-width: 0;
    background-color: var(--f-supplementary-purple);
  }
  &.live {
    border-width: 0;
    background-color: #ffc32b;
  }
}
</style>
