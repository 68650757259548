<template>
  <v-tooltip bottom open-on-hover :content-class="`info-hover ${$vuetify.theme.dark ? 'dark' : 'light'}`">
    <template v-slot:activator="{ on, attrs }">
      <img v-if="themeName === 'dark'" src="@/assets/Info_Line_16px_Icon_Dark.svg" alt="info" v-bind="attrs" v-on="on" width="14px" />
      <img v-else src="@/assets/Info_Line_16px_Icon_Light.svg" alt="info" v-bind="attrs" v-on="on" width="14px" />
    </template>
    <div>{{ getHoverText }}</div>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    isRepetitive: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    themeName() {
      return this.$vuetify.theme.dark ? 'dark' : 'light'
    },
    getHoverText() {
      if (this.isRepetitive) return 'First Detection to Repetitive'
      else return 'Repetitive to First Detection'
    },
  },
}
</script>

<style lang="scss" scoped>
img {
  margin-left: 10px;
}
.info-hover {
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  width: 211px;
  height: 33px;
  &.dark {
    background-color: var(--f-bg-high) !important;
    color: #ffffffde;
  }
  &.light {
    background-color: #ffffff !important;
    color: #ff827c;
  }
}
</style>
