import { render, staticRenderFns } from "./LogInfo.vue?vue&type=template&id=273d83ef&scoped=true&"
import script from "./LogInfo.vue?vue&type=script&lang=js&"
export * from "./LogInfo.vue?vue&type=script&lang=js&"
import style0 from "./LogInfo.vue?vue&type=style&index=0&id=273d83ef&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "273d83ef",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VSpacer,VTooltip})
