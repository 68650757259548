<template>
  <div class="live-container">
    <div class="live-image" :style="imageStyle"></div>
  </div>
</template>

<script>
import Constants from '@/constants'
import Util from '@/util'

export default {
  props: {
    currentCam: Object,
  },
  data() {
    return {
      intervalId: null,
      currentCamImageSrc: null,
    }
  },
  mounted() {
    this.initialize()
  },
  destroyed() {
    clearTimeout(this.intervalId)
  },
  computed: {
    imageStyle() {
      return {
        backgroundImage: `url(${this.currentCamImageSrc})`,
        backgroundSize: 'contain',
        aspectRatio: '16 / 9',
        width: '100%',
        backgroundPosition: 'center',
      }
    },
  },
  watch: {
    currentCam(nextCam, prevCam) {
      if (prevCam && nextCam.id !== prevCam.id) {
        clearTimeout(this.intervalId)
        this.initialize()
      }
    },
  },
  methods: {
    initialize() {
      this.currentCamImageSrc = Util.getImageUrl(this.currentCam.path, true)
      this.imageLoadInterval()
    },
    imageLoadInterval() {
      clearTimeout(this.intervalId)
      this.currentCamImageSrc = Util.getImageUrl(this.currentCam.path, true)
      this.intervalId = setTimeout(() => {
        this.currentCamImageSrc = Util.getImageUrl(this.currentCam.path, true)
        this.imageLoadInterval()
      }, Constants.INTERVAL_SITE_LOG)
    },
  },
}
</script>

<style lang="scss" scoped>
.live-container {
  width: 100%;
  height: calc(100% - 100px);
  overflow: hidden;
  background-color: black;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
</style>
