<template>
  <div>
    <div class="camera-info">
      <div :class="`camera-name-text ${themeName}`">{{ cameraName }}</div>
      <img v-if="alertOff" src="@/assets/Alert_Off_SkyBlue_Icon.svg" width="24px" style="margin-left: -13px; margin-right: 15px; flex-shrink: 0" />
      <div class="label"></div>
      <div :class="`camera-info-text ${themeName}`">Live View</div>
      <v-spacer></v-spacer>
      <div :class="`camera-provider ${themeName}`" style="display: flex; align-items: center" v-if="providerLogoUrl">
        Cameras by
        <img :src="providerLogoUrl" width="132px" style="margin: 0 43px 0 7px" />
      </div>
    </div>
    <div :class="`camera-detail ${themeName}`">
      <div class="detail-label draggable">Camera Location</div>
      <div class="draggable">{{ cameraLocation }}</div>
      <v-spacer></v-spacer>
      <div :class="`link ${themeName}`" @click="dialogGoto = true">
        <img v-if="$vuetify.theme.dark" src="@/assets/Goto_Line_16px_Icon.svg" width="16px" style="margin-right: 7px" />
        <img v-else src="@/assets/Goto_Line_16px_Icon-red.svg" width="16px" style="margin-right: 7px" />
        <span>Go to</span>
      </div>
    </div>
    <GotoDialog v-model="dialogGoto" :urls="goToLinks"></GotoDialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import GotoDialog from '@desktop/dialog/GotoDialogAdmin'
import Theme from '@/mixin/theme.mixin'
import AlertOff from '@/mixin/alertoff.mixin'
import Util from '@/util'

export default {
  mixins: [Theme, AlertOff],
  components: { GotoDialog },
  data() {
    return {
      dialogGoto: false,
    }
  },
  computed: {
    ...mapState(['currentCam', 'user']),
    providerLogoUrl() {
      if (this.currentCam.provider_dark_logo_path || this.currentCam.provider_light_logo_path) {
        const currentLogoUrl = this.themeName === 'dark' ? this.currentCam.provider_dark_logo_path : this.currentCam.provider_light_logo_path

        return Util.getImageUrl(currentLogoUrl)
      }

      return null
    },
    camera() {
      if (this.currentCam) {
        return this.currentCam
      }
      return null
    },
    cameraName() {
      if (this.camera) {
        return this.camera.name
      }
      return '-'
    },
    cameraLocation() {
      if (this.camera) {
        return `${this.camera.coordinate}, ${this.camera.name}`
      }
      return '-'
    },
    goToLinks() {
      if (this.camera) {
        return [this.camera.public_url, this.camera.private_url_1, this.camera.private_url_2]
      }
      return []
    },
    alertOff() {
      return this.isAlertOff(this.currentCam)
    },
  },
}
</script>

<style lang="scss" scoped>
.camera-info {
  display: flex;
  align-items: center;
  min-height: 72px;
  color: var(--f-text-white-high);
}
.camera-name-text {
  margin: 0 20px 0 30px;
  font-weight: 600;
  font-size: 18px;
  color: var(--f-primary-70);
  &.dark {
    color: var(--f-text-white-high);
  }
}

.camera-provider {
  color: var(--f-text-black);
  &.dark {
    color: var(--f-text-white-high);
  }
}

.camera-info-text {
  font-weight: 400;
  font-size: 16px;
  margin-right: 10px;
  color: var(--f-text-gray);
  &.dark {
    color: var(--f-text-white-high);
  }
}

.camera-detail-button {
  width: 32px;
  margin-right: 30px;
  cursor: pointer;
  transition: transform 0.1s linear;

  &.open {
    transform: rotate(-180deg);
  }
}

.label {
  min-width: 10px;
  min-height: 10px;
  border-radius: 10px;
  background-color: var(--f-supplementary-yellow);
  margin-right: 10px;
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 37px;
}

.flex-column {
  display: flex;
  flex-direction: column;
  min-width: 420px;
}

.split-line {
  width: 100%;
  height: 1px;
  background-color: var(--f-bg-high);
}

.camera-detail {
  display: flex;
  align-items: center;
  margin: 0 30px;
  padding: 0 30px;
  height: 68px;
  margin-top: -5px;
  margin-bottom: 20px;
  border-radius: 8px;
  font-size: 14px;
  color: var(--f-text-black);
  background-color: var(--f-light-gray-1);
  &.dark {
    color: var(--f-text-white-high);
    background-color: var(--f-bg-middle);
  }

  .detail-label {
    font-weight: 600;
    margin-right: 54px;
  }

  .link {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    color: var(--f-primary-70);
    &.dark {
      color: var(--f-text-white-high);
    }
  }
}
</style>
