<template>
  <div>
    <div :class="`log-info ${themeName}`">
      <div :class="`log-name-text ${themeName}`" @click="moveToLiveView">{{ cameraName }}</div>
      <img v-if="alertOff" src="@/assets/Alert_Off_SkyBlue_Icon.svg" width="24px" style="margin-left: -13px; margin-right: 15px; flex-shrink: 0" />
      <div class="log-info-text">{{ logName }}</div>
      <RepetitiveInfoTooltip v-if="showTooltipIcon" :isRepetitive="isRepetitive" />
      <EventCircle v-if="!isViewer" :value="eventType.id" style="margin-left: 11px" />
      <RepetitiveButton v-if="isRepetitive" :rootLogId="currentLog.root_log_id" @showRootLog="showRootLog" />
      <v-spacer></v-spacer>
      <div style="display: flex; align-items: center" v-if="providerLogoUrl">
        Cameras by
        <img :src="providerLogoUrl" width="132px" style="margin: 0 43px 0 7px" />
      </div>
    </div>
    <div :class="`log-detail ${themeName}`">
      <div class="flex-column">
        <div class="flex-row">
          <div :class="`detail-label ${themeName}`">Event Type</div>
          <v-spacer></v-spacer>
          <div :class="`detail-value ${themeName}`">{{ eventType.id === 1 || isViewer ? '-' : eventType.name }}</div>
        </div>
        <div :class="`split-line ${themeName}`"></div>
        <div class="flex-row">
          <div :class="`detail-label draggable ${themeName}`">Event ID</div>
          <v-spacer></v-spacer>
          <div :class="`detail-value draggable ${themeName}`">#{{ info && info.log_id ? info.log_id : '-' }}</div>
        </div>
        <div :class="`split-line ${themeName}`"></div>
        <div class="flex-row">
          <div :class="`detail-label draggable ${themeName}`">Fire Location</div>
          <v-spacer></v-spacer>
          <div
            :class="`detail-value ${fireLocationValue === '-' ? 'text-no-drag' : 'draggable'} ${themeName}`"
            style="display: flex; align-items: center"
          >
            <v-tooltip v-if="fireLocationIsTriangulated" top :content-class="`triangulated-tooltip ${themeName}`">
              <template v-slot:activator="{ on, attrs }">
                <img
                  v-if="themeName === 'dark'"
                  src="@/assets/Info_Line_16px_Icon_Dark.svg"
                  v-bind="attrs"
                  v-on="on"
                  class="triangulated-tooltip-icon"
                  height="14"
                  width="14"
                />
                <img
                  v-else
                  src="@/assets/Info_Line_16px_Icon_Light.svg"
                  v-bind="attrs"
                  v-on="on"
                  class="triangulated-tooltip-icon"
                  height="14"
                  width="14"
                />
              </template>
              <span :class="`triangulated-tooltip-text ${themeName}`">Triangulated</span>
            </v-tooltip>
            <img
              v-if="fireLocationValue !== '-'"
              src="@/assets/Mobile_Copy_Button_Red.svg"
              height="14"
              style="margin-right: 12px; cursor: pointer"
              @click="onClickCopy('location')"
            />
            <img v-else-if="themeName === 'dark'" src="@/assets/Mobile_Copy_Button_Disabled-Dark.svg" height="14" style="margin-right: 12px" />
            <img v-else src="@/assets/Mobile_Copy_Button_Disabled-Light.svg" height="14" style="margin-right: 12px" />
            {{ fireLocationValue }}
          </div>
        </div>
        <div :class="`split-line ${themeName}`"></div>
      </div>
      <div style="width: 60px"></div>
      <div class="flex-column">
        <div class="flex-row">
          <div :class="`detail-label draggable ${themeName}`">Detected Time</div>
          <v-spacer></v-spacer>
          <div :class="`detail-value draggable ${themeName}`">{{ detectionTime }}</div>
        </div>
        <div :class="`split-line ${themeName}`"></div>
        <div class="flex-row">
          <div :class="`detail-label draggable ${themeName}`">Camera Location</div>
          <v-spacer></v-spacer>
          <div :class="`detail-value draggable ${themeName}`">{{ cameraLocation }}</div>
        </div>
        <div :class="`split-line ${themeName}`"></div>
        <div class="flex-row">
          <div :class="`detail-label draggable ${themeName}`">Fire Direction</div>
          <v-spacer></v-spacer>
          <div
            :class="`detail-value ${fireDirectionValue === '-' ? 'text-no-drag' : 'draggable'} ${themeName}`"
            style="display: flex; align-items: center"
          >
            <img
              v-if="fireDirectionValue !== '-'"
              src="@/assets/Mobile_Copy_Button_Red.svg"
              height="14"
              style="margin-right: 12px; cursor: pointer"
              @click="onClickCopy('direction')"
            />
            <img v-else-if="themeName === 'dark'" src="@/assets/Mobile_Copy_Button_Disabled-Dark.svg" height="14" style="margin-right: 12px" />
            <img v-else src="@/assets/Mobile_Copy_Button_Disabled-Light.svg" height="14" style="margin-right: 12px" />
            {{ fireDirectionValue }}
          </div>
        </div>
        <div :class="`split-line ${themeName}`"></div>
        <div style="height: 40px; display: flex; align-items: center; justify-content: flex-end">
          <Button
            v-if="edit"
            @click="showEditLabelDialog = true"
            :disabled="isViewer || isRepetitive"
            :colorStrength="90"
            style="width: 80px; height: 34px; margin-right: 30px; font-size: 14px"
            >Edit</Button
          >
          <div :class="`link ${themeName}`" @click="dialogGoto = true">
            <img v-if="$vuetify.theme.dark" src="@/assets/Goto_Line_16px_Icon.svg" width="16px" style="margin-right: 7px" />
            <img v-else src="@/assets/Goto_Line_16px_Icon-red.svg" width="16px" style="margin-right: 7px" />
            <span style="margin-right: 20px">Go to</span>
          </div>
          <div :class="`link ${themeName}`" @click="onClickLinkShare">
            <img v-if="$vuetify.theme.dark" src="@/assets/Link_Line_16px_Icon.svg" width="16px" style="margin-right: 7px" />
            <img v-else src="@/assets/Link_Line_16px_Icon-red.svg" width="16px" style="margin-right: 7px" />
            <span style="margin-right: 10px">Share</span>
          </div>
        </div>
      </div>
    </div>
    <GotoDialog v-model="dialogGoto" :urls="goToLinks"></GotoDialog>
    <SnackBar v-model="clipboard" :color="clipboardColor" :message="clipboardMsg"></SnackBar>
    <EditLabelDialog v-model="showEditLabelDialog" :data="currentLog" @ok="onClickSaveLabel"></EditLabelDialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Util from '@/util'
import GotoDialog from '@desktop/dialog/GotoDialogAdmin'
import SnackBar from '@common/SnackBarClipboard'
import EventCircle from '@common/EventCircle'
import Button from '@common/Button'
import EditLabelDialog from '@desktop/dialog/EditLabelDialogAdmin'
import RepetitiveButton from '@common/RepetitiveButton'
import { alert } from '@/api'
import Theme from '@/mixin/theme.mixin'
import AlertOff from '@/mixin/alertoff.mixin'
import RepetitiveInfoTooltip from '@common/RepetitiveInfoTooltip'

export default {
  mixins: [Theme, AlertOff],
  components: { GotoDialog, SnackBar, EventCircle, Button, EditLabelDialog, RepetitiveButton, RepetitiveInfoTooltip },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogGoto: false,
      clipboard: false,
      clipboardColor: '',
      clipboardMsg: '',
      showEditLabelDialog: false,
    }
  },
  computed: {
    ...mapState(['camMap', 'currentLog', 'recentLogList', 'eventTypeList', 'user', 'camList']),
    providerLogoUrl() {
      if (this.currentLog.provider_dark_logo_path || this.currentLog.provider_light_logo_path) {
        const currentLogoUrl = this.themeName === 'dark' ? this.currentLog.provider_dark_logo_path : this.currentLog.provider_light_logo_path

        return Util.getImageUrl(currentLogoUrl)
      }

      return null
    },
    info() {
      if (this.currentLog) {
        return this.currentLog
      } else if (this.recentLogList.length) {
        return this.recentLogList[0]
      }
      return null
    },
    showTooltipIcon() {
      if (this.info) return this.info.is_checked_organization
      else return false
    },
    isRepetitive() {
      if (this.currentLog) {
        return Util.isDuplicateLog(this.currentLog)
      }
      return false
    },
    camera() {
      if (this.info) {
        return this.camMap[this.info.camera_id]
      }
      return null
    },
    cameraId() {
      if (this.info) {
        return this.info.camera_id
      }
      return null
    },
    cameraName() {
      if (this.camera) {
        return this.camera.name
      }
      return '-'
    },
    cameraLocation() {
      if (this.camera) {
        return `${this.camera.coordinate}, ${this.cameraName}`
      }
      return '-'
    },
    logName() {
      if (this.info) {
        return `[${this.detectionTime}] ${this.info.camera_name}`
      }
      return ''
    },
    detectionTime() {
      if (this.info) {
        return Util.getOffsetDate(this.info.created_at, this.info.offset)
      }
      return '-'
    },
    eventType() {
      if (this.info) {
        const event = this.eventTypeList.find((e) => e.id === this.info.event_type_id)
        return event
      }
      return null
    },
    fireLocationValue() {
      if (!this.info) return '-'
      const latitude = this.info.latitude
      const longitude = this.info.longitude
      if (latitude && latitude !== -1 && longitude && longitude !== -1) return latitude + ' ' + longitude
      return '-'
    },
    fireLocationIsTriangulated() {
      return this.info?.is_triangulated
    },
    fireDirectionValue() {
      if (!this.info) return '-'
      const panDirection = this.info.pan_direction
      if (panDirection && panDirection !== -1) return Util.locaitonToDegree(panDirection)
      return '-'
    },
    location() {
      if (this.fireLocationValue !== '-') return this.fireLocationValue
      else if (this.fireLocationValue === '-' && this.fireDirectionValue !== '-') return this.fireDirectionValue
      return '-'
    },
    goToLinks() {
      if (this.camera) {
        return [this.camera.public_url, this.camera.private_url_1, this.camera.private_url_2]
      }
      return []
    },
    alertOff() {
      return this.isAlertOff(this.currentLog, this.camMap)
    },
    isViewer() {
      return this.user.isViewer
    },
  },
  methods: {
    ...mapActions(['getRecentLogList']),
    showRootLog(rootLog) {
      const isDetectionPage = this.$route.path.includes('detection')

      if (!isDetectionPage) {
        this.$emit('onClickRootLog', rootLog)

        return
      }

      location.href = `detection?id=${rootLog.log_id}`
    },
    onClickCopy(flag) {
      try {
        this.clipboardColor = 'secondary'
        this.clipboardMsg = 'Copied to clipboard.'
        navigator.clipboard.writeText(flag === 'location' ? this.fireLocationValue : this.fireDirectionValue)
        this.clipboard = true
      } catch (e) {
        this.clipboard = true
        this.clipboardColor = 'error'
        this.clipboardMsg = e.toString()
      }
    },
    onClickLinkShare() {
      try {
        this.clipboardColor = 'secondary'
        this.clipboardMsg = 'Copied to clipboard.'
        Util.copyClipboard(`${location.origin}/detection?name=${this.info.camera_name}&id=${this.info.log_id}`)
        this.clipboard = true
      } catch (e) {
        this.clipboard = true
        this.clipboardColor = 'error'
        this.clipboardMsg = e.toString()
      }
    },
    async onClickSaveLabel(event_type_id) {
      try {
        await alert.updateEventType({ log_id: this.currentLog.log_id, event_type_id })
        await this.$store.dispatch('setCurrentLog', { ...this.currentLog, event_type_id })
        await this.getRecentLogList()
        this.$emit('update')
      } catch (e) {
        this.$log.error(e)
      }
    },

    moveToLiveView() {
      const cam = this.camList.find((cam) => cam.id === this.cameraId)
      this.$store.dispatch('setCurrentCam', cam)
      const isDetectionPage = this.$route.path.includes('detection')
      if (isDetectionPage) {
        this.$router.push({ path: `/detection?name=${cam.name}` })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.triangulated-tooltip {
  background-color: #ffffff;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);

  &.dark {
    background-color: #323340;
  }
}

.triangulated-tooltip-text {
  font-weight: 400;
  font-size: 14px;
  color: #ff827c;

  &.dark {
    color: #ffffffde;
  }
}

.triangulated-tooltip-icon {
  margin-right: 10px;
}

.log-info {
  display: flex;
  align-items: center;
  min-height: 72px;
  color: var(--f-text-black);
  &.dark {
    color: var(--f-text-white-high);
  }
}
.log-name-text {
  margin: 0 20px 0 30px;
  font-weight: 600;
  font-size: 18px;
  user-select: text;
  color: var(--f-text-black);
  cursor: pointer;
  &.dark {
    color: #ffffffde;
  }
}

.log-info-text {
  font-weight: 500;
  font-size: 14px;
  user-select: text;
}

.log-detail-button {
  width: 32px;
  margin-right: 30px;
  cursor: pointer;
  transition: transform 0.1s linear;

  &.open {
    transform: rotate(-180deg);
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 19px;
}

.flex-column {
  display: flex;
  flex-direction: column;
  min-width: 420px;
  width: 0;
  flex-grow: 1;
}

.split-line {
  width: 100%;
  height: 1px;
  background-color: var(--f-light-gray-2);
  margin: 8px 0;
  &.dark {
    background-color: var(--f-bg-high);
  }
}

.log-detail {
  display: flex;
  margin: 0 30px;
  padding: 25px 20px 0;
  height: 183px;
  margin-top: -5px;
  margin-bottom: 20px;
  border-radius: 24px;
  font-size: 14px;
  font-weight: 400;
  background-color: var(--f-light-gray-1);
  &.dark {
    background-color: var(--f-bg-middle);
  }

  .detail-label {
    font-weight: 500;
    margin-right: 10px;
    padding-left: 10px;
    white-space: nowrap;
    color: var(--f-text-gray);
    &.dark {
      color: var(--f-text-white-high);
    }
  }

  .detail-value {
    font-weight: 600;
    white-space: nowrap;
    color: var(--f-text-black);
    &.dark {
      color: var(--f-primary-50);
    }
  }

  .link {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 500;
    color: var(--f-primary-70);
    &.dark {
      color: var(--f-text-white-high);
    }
    span {
      font-size: 16px;
    }
  }
}
</style>
