<template>
  <div ref="tooltip" class="tooltip-container" :style="style">
    <div class="tooltip-title">{{ title }}</div>
    <div ref="content" class="tooltip-content">{{ content }}</div>
  </div>
</template>

<script>
import Util from '@/util'

export default {
  props: {
    title: String,
    content: String,
    direction: {
      type: String,
      default: 'down', // up
    },
  },
  data() {
    return {
      style: '',
    }
  },
  mounted() {
    const lineCount = Util.getTextLineCount(this.$refs.content)
    switch (lineCount) {
      case 1:
        this.$refs.tooltip.style.height = '100px'
        break
      case 2:
        this.$refs.tooltip.style.height = '120px'
        break
      default:
        this.$refs.tooltip.style.height = '140px'
    }
    if (this.direction === 'up' && this.$refs.tooltip) {
      this.style = `margin-top:-${this.$refs.tooltip.clientHeight + 40}px;`
      if (!this.$vuetify.theme.dark) {
        this.style += 'box-shadow: 5px 5px 20px rgba(61, 81, 251, 0.1);'
      }
    } else {
      if (!this.$vuetify.theme.dark) {
        this.style = 'box-shadow: 5px 5px 20px rgba(61, 81, 251, 0.1);'
      } else {
        this.style = ''
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.tooltip-container {
  position: absolute;
  padding: 15px 20px;
  background-color: var(--v-backgroundAsideTooltip-base);
  width: 300px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-top: 10px;
  z-index: 2;

  .tooltip-title {
    font-weight: 500;
    font-size: 16px;
    color: var(--v-textAside);
    margin-bottom: 10px;
  }

  .tooltip-content {
    font-size: 15px;
    color: var(--v-textDefault);
  }
}
</style>
