var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'event-circle': true,
    fire: _vm.value === 2,
    'non-fire': _vm.value === 3,
    unknown: _vm.value === 4,
    planned: _vm.value === 5,
    live: _vm.value === 1006,
  },attrs:{"data-testid":"EventType_Fire_Ellipse"}})}
var staticRenderFns = []

export { render, staticRenderFns }