<template>
  <footer>
    <div style="display: flex; justify-content: flex-end">
      <ButtonTheme></ButtonTheme>
    </div>
    <section>
      <router-link data-testid="Support_Small_Button" :class="`link ${themeName}`" to="/support-submit">Contact Support</router-link>
      <div :class="`split-line ${themeName}`"></div>
      <a :class="`link ${themeName}`" href="http://docs.firescout.ai/user-manual/user-manual-for-pc" target="_blank">User Manual</a>
      <div class="spacer-1"></div>
      <div :class="themeName">ⓒ 2022 Alchera Inc.</div>
      <img
        v-if="$vuetify.theme.dark"
        src="@/assets/PoweredLogo_white.svg"
        width="150px"
        style="margin-bottom: 1px; margin-left: 5px; margin-right: 15px"
      />
      <img v-else src="@/assets/PoweredLogo_color.svg" width="150px" style="margin-bottom: 1px; margin-left: 5px; margin-right: 15px" />
      <div :class="themeName">Cameras provided by</div>
      <img v-if="$vuetify.theme.dark" src="@/assets/wildfire-logo-white.png" width="110px" style="margin-left: 10px; margin-bottom: 6px" />
      <img v-else src="@/assets/wildfire-logo-black-150px.png" width="110px" style="margin-left: 10px; margin-bottom: 6px" />
      <img v-if="$vuetify.theme.dark" src="@/assets/AlertCA_Logo_White.svg" width="133px" style="margin-left: 10px; margin-bottom: 6px" />
      <img v-else src="@/assets/AlertCA_Logo_Black.svg" width="133px" style="margin-left: 10px; margin-bottom: 6px" />
    </section>
  </footer>
</template>

<script>
import ButtonTheme from './ButtonTheme'
import Theme from '@/mixin/theme.mixin'

export default {
  mixins: [Theme],
  components: { ButtonTheme },
  props: {
    /**
     * emit events
     * terms
     * policy
     */
    signup: Boolean,
  },
  data() {
    return {
      tooltip: {
        userManual: {
          title: 'User Manual',
          content: 'Click to navigate to the user manual.',
        },
      },
    }
  },
  methods: {
    onClickTerms() {
      this.$emit('terms')
    },
    onClickPolicy() {
      this.$emit('policy')
    },
  },
}
</script>

<style lang="scss" scoped>
footer {
  display: flex;
  flex-direction: column;
  min-height: 90px;
  margin: 100px 24px 30px 30px;
  font-size: 13px;
  color: var(--f-text-white-high);

  .split-line {
    width: 1px;
    height: 20px;
    margin: 0 10px;
    background-color: var(--f-text-black);
    &.dark {
      background-color: var(--f-text-white-high);
    }
  }

  .link {
    cursor: pointer;
    text-decoration: none;
  }

  section {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;

    color: var(--f-text-black);
    .dark {
      color: var(--f-text-white-high);
    }
  }
}
</style>
