import moment from 'moment'

export default {
  methods: {
    isAlertOff(currentLog, camMap) {
      const now = moment()
      let cam = null
      if (!camMap) {
        cam = currentLog
      } else if (currentLog) {
        cam = camMap[currentLog.camera_id]
      }
      if (cam && cam.noti_pause_start && cam.noti_pause_end) {
        if (now.isAfter(cam.noti_pause_start) && now.isBefore(cam.noti_pause_end)) {
          return true
        }
      }
      return false
    },
  },
}
